import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import LogoGray from './icons/LogoGray.jsx';
import ArrowRight from './icons/ArrowRight';
import FeatherDarkBrown from './icons/FeatherDarkBrown';

import { subscribe } from 'klaviyo-subscribe';
import { email as emailRegex } from 'magic-tricks/lib/regex';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import phoneNumberFormatter from 'phone-number-formats';
import useIntersection from 'buena-suerte/lib/hooks/useIntersection';

export default ({ address, email, phone, bio, links = [] }) => {
  const [state, setState] = useState({
    userEmail: '',
    showInvalidEmailError: false,
    errorMessage: null,
    isSubmitting: false,
    isSuccess: false,
  });

  const {
    userEmail,
    showInvalidEmailError,
    errorMessage,
    isSubmitting,
    isSuccess,
  } = state;

  const intersectionRef = useRef();
  const isIntersected = useIntersection(intersectionRef, { once: false });

  const onChangeEmail = event => {
    const { showInvalidEmailError } = state;
    setState({
      ...state,
      userEmail: event.target.value,
      showInvalidEmailError: showInvalidEmailError
        ? !emailRegex.test(event.target.value)
        : showInvalidEmailError,
    });
  };

  const onSubmit = event => {
    event.preventDefault();

    const { userEmail } = state;

    if (!emailRegex.test(userEmail)) {
      setState({
        ...state,
        showInvalidEmailError: true,
        isSubmitting: false,
      });

      return;
    }

    setState({
      ...state,
      isSubmitting: true,
      showInvalidEmailError: false,
    });

    subscribe(process.env.GATSBY_KLAVIYO_LIST_ID, userEmail)
      .then(response => {
        if (response.errors.length) {
          setState({
            ...state,
            isSubmitting: false,
            errorMessage: response.errors.join(' '),
          });
        } else {
          setState({
            ...state,
            isSubmitting: false,
            isSuccess: true,
          });
        }
      })
      .catch(error => {
        console.error(error);

        setState({
          ...state,
          isSubmitting: false,
          errorMessage: 'Uh oh, an error occured.',
        });
      });
  };

  const phoneNumber = phone
    ? new phoneNumberFormatter(phone.toString()).format({
        type: 'domestic',
      })
    : null;

  const footerContact = (
    <aside className="site-footer__contact p2">
      <Link to="/" className="mb2 dib ">
        <FeatherDarkBrown className="footer__logo" />
      </Link>
      <div className="dig x site-footer__contact--address">
        {address && <BlockContent blocks={address} serializers={richText} />}
        <nav className="contact-links">
          {phone && (
            <a className="db" href={`tel:${phone}`}>
              {phoneNumber.string}
            </a>
          )}
          {email && (
            <a className="db" href={`mailto:${email}`}>
              {email}
            </a>
          )}
        </nav>
      </div>
    </aside>
  );

  const footerNewsletter = (
    <aside className="site-footer__contact df fdc jcb p2">
      <div className="mb1 mt2">
        <h4 className="serif--sm mb2">Join the Fam</h4>

        <p className="sans--sm">
          Stay up to date on the latest releases, restocks and designers from
          eugenie.{' '}
        </p>
      </div>
      <form className="newsletter-modal__form pr" onSubmit={onSubmit}>
        <label className="df fw pr aic x" htmlFor="newsletter-modal">
          <input
            disabled={isSuccess || isSubmitting}
            id="newsletter-modal"
            name="email"
            type="email"
            className={classNames('input--newsletter fw', {
              loading: isSubmitting,
            })}
            value={userEmail}
            placeholder="Email Address"
            onChange={onChangeEmail}
          />
          <button
            disabled={isSuccess || isSubmitting}
            type="submit"
            className={classNames('input--newsletter__submit link--opacity', {
              loading: isSubmitting,
            })}
            onClick={onSubmit}
          >
            <ArrowRight />
          </button>
        </label>
      </form>

      {isSuccess && <p className="mt1">Thanks for signing up!</p>}

      {(showInvalidEmailError || errorMessage) && (
        <p className="mt1 color--red">
          {errorMessage
            ? errorMessage
            : showInvalidEmailError
            ? 'Please enter a valid email.'
            : null}
        </p>
      )}
    </aside>
  );

  const footerNav = (
    <nav className="site-footer__links p2 df fdc jce">
      <div className="site-footer__links-list dig">
        {links &&
          links.map(link => (
            <a
              target={link.openInNewWindow ? '_blank' : null}
              rel={link.openInNewWindow ? 'noreferrer noopener' : null}
              key={link._key}
              href={link.url}
            >
              {link.title}
            </a>
          ))}
      </div>
    </nav>
  );

  const footerSmall = (
    <section className="site-footer__credits x p2 pt3 bt--dark-brown">
      <small className="copyright sans--xs">
        © {new Date().getFullYear()} eugenie
      </small>
    </section>
  );

  return (
    <footer
      ref={intersectionRef}
      className={classNames('site-footer bt--dark-brown df fw reveal', {
        revealed: isIntersected,
      })}
    >
      <div className="x db">
        <div className="of--hidden ">
          <ul className="banner banner--footer x p1 p2--md ws--nowrap list list--horizontal gutter--none list--forward bb--dark-brown">
            {Array.apply(null, Array(40)).map((item, index) => (
              <li key={index} className="list__item rich-text serif--sm">
                <div className="banner__item banner__item--footer px-20">
                  <LogoGray
                    className={classNames('', {
                      'rotate-180': index % 2 === 0,
                    })}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <section className="site-footer__tabs df x">
        {footerContact}
        {footerNewsletter}
        {footerNav}
      </section>
      {footerSmall}
    </footer>
  );
};
