import React, { useEffect, useState, useContext } from 'react';
import Context from '../context/Context';
import Close from './icons/Close';
import ArrowRight from './icons/ArrowRight';

import classNames from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

import { subscribe } from 'klaviyo-subscribe';
import { email as emailRegex } from 'magic-tricks/lib/regex';
import { Transition } from 'react-transition-group';

const TRANSITION_DURATION = 400;
const OPEN_DELAY = 2000;

const DEFAULT_STYLE = {
  transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0.25, 0, 1), opacity ${TRANSITION_DURATION}ms ease`,
  transform: 'translate3d(0, 30px, 0)',
  opacity: 0,
};

const TRANSITION_STYLES = {
  entering: {
    transform: 'translate3d(0, 30px, 0)',
    opacity: 0,
  },
  entered: {
    transform: 'translate3d(0, 0, 0)',
    opacity: 1,
  },
  exited: {
    transform: 'translate3d(0, 0, 0)',
    opacity: 0,
  },
  exiting: { transform: 'translate3d(0, 0, 0)' },
};

const NewsletterModal = ({
  title,
  description,
  placeholder,
  klaviyoListId,
  klaviyoSource = '',
}) => {
  const { hasSeenNewsletter, setSeenNewsletter } = useContext(Context);

  const [state, setState] = useState({
    email: '',
    showInvalidEmailError: false,
    errorMessage: null,
    isSubmitting: false,
    isSuccess: false,
  });

  const [isOpen, setIsOpen] = useState(false);

  const {
    email,
    showInvalidEmailError,
    errorMessage,
    isSubmitting,
    isSuccess,
  } = state;

  // Open model
  useEffect(() => {
    if (!hasSeenNewsletter) {
      setTimeout(() => {
        setIsOpen(true);
      }, OPEN_DELAY);
    }
  }, [hasSeenNewsletter]);

  const onChangeEmail = event => {
    const { showInvalidEmailError } = state;
    setState({
      ...state,
      email: event.target.value,
      showInvalidEmailError: showInvalidEmailError
        ? !emailRegex.test(event.target.value)
        : showInvalidEmailError,
    });
  };

  const onSubmit = event => {
    event.preventDefault();

    const { email } = state;

    if (!emailRegex.test(email)) {
      setState({
        ...state,
        showInvalidEmailError: true,
        isSubmitting: false,
      });

      return;
    }

    setState({
      ...state,
      isSubmitting: true,
      showInvalidEmailError: false,
    });

    subscribe(klaviyoListId, email)
      .then(response => {
        if (response.errors.length) {
          setState({
            ...state,
            isSubmitting: false,
            errorMessage: response.errors.join(' '),
          });
        } else {
          setState({
            ...state,
            isSubmitting: false,
            isSuccess: true,
          });
          setSeenNewsletter();
        }
      })
      .catch(error => {
        console.error(error);

        setState({
          ...state,
          isSubmitting: false,
          errorMessage: 'Uh oh, an error occurred.',
        });
      });
  };

  return (
    <Transition
      in={isOpen}
      mountOnEnter
      unmountOnExit
      appear
      timeout={{
        enter: 100,
        exit: TRANSITION_DURATION,
      }}
    >
      {state => (
        <div
          style={{
            ...DEFAULT_STYLE,
            ...TRANSITION_STYLES[state],
          }}
          className="newsletter__modal p2"
        >
          <div className="mb1">
            <h4 className="serif--sm mb2">Join the Fam</h4>
            {description && (
              <div className="sans--sm rich-text line-break">
                <BlockContent blocks={description} serializers={richText} />
              </div>
            )}
            {!description && (
              <p className="sans--sm">
                Stay up to date on the latest releases, restocks and designers
                from eugenie.{' '}
              </p>
            )}
          </div>
          <form className="newsletter-modal__form pr" onSubmit={onSubmit}>
            <label className="df fw pr aic x" htmlFor="newsletter-modal">
              <input
                disabled={isSuccess || isSubmitting}
                id="newsletter-modal"
                name="email"
                type="email"
                className={classNames('input--newsletter fw', {
                  loading: isSubmitting,
                })}
                value={email}
                placeholder={placeholder || 'Email Address'}
                onChange={onChangeEmail}
              />
              <button
                disabled={isSuccess || isSubmitting}
                type="submit"
                className={classNames(
                  'input--newsletter__submit link--opacity',
                  {
                    loading: isSubmitting,
                  }
                )}
                onClick={onSubmit}
              >
                <ArrowRight />
              </button>
            </label>
          </form>

          {isSuccess && <p className="mt1">Thanks for signing up!</p>}

          {(showInvalidEmailError || errorMessage) && (
            <p className="mt1 color--red">
              {errorMessage
                ? errorMessage
                : showInvalidEmailError
                ? 'Please enter a valid email.'
                : null}
            </p>
          )}

          <button
            className="db newsletter-modal__close link--opacity"
            onClick={() => {
              setIsOpen(false);
              setTimeout(() => {
                setSeenNewsletter();
              }, TRANSITION_DURATION);
            }}
          >
            <Close />
          </button>
        </div>
      )}
    </Transition>
  );
};

export default NewsletterModal;
